<template>
  <v-dialog :value="show" persistent width="1600">
    <v-card class="pt-2">
      <v-card-title>Пошук анкети клієнта</v-card-title>
      <div class="d-flex flex-column px-6">
        <div class="search-row d-flex align-center mb-4">
          <v-select
            :items="searchByFields"
            label="Поле для пошуку"
            v-model="form.fieldName.value"
          ></v-select>
          <v-text-field
            :loading="loading"
            :error="!!form.fieldValue.error"
            :error-messages="form.fieldValue.error"
            v-model="form.fieldValue.value"
            clearable
            label="Вартість поля для пошуку"
          ></v-text-field>
          <v-btn @click="findRecords" color="primary" :loading="loading">
            Пошук
            <v-icon right dark>mdi-magnify</v-icon>
          </v-btn>
        </div>
        <v-data-table
          @click:row="handleRowClick"
          :headers="headers"
          :item-class="getItemClass"
          :items="searchResults"
          :items-per-page="-1"
          :loading="loading"
          height="300"
          hide-default-footer
        >
          <template v-slot:item.isContract="{ value }">
            <span>{{ renderBooleanValue(value) }}</span>
          </template>
          <template v-slot:item.isStudent="{ value }">
            <span>{{ renderBooleanValue(value) }}</span>
          </template>
          <template v-slot:item.isDocumentMissing="{ value }">
            <span>{{ renderBooleanValue(value) }}</span>
          </template>
          <template v-slot:item.isCashPay="{ value }">
            <span>{{ renderBooleanValue(value) }}</span>
          </template>
        </v-data-table>
        <v-divider />
        <div class="d-flex align-center flex-wrap my-4">
          <span class="mr-4 font-weight-bold">ID клієнта:</span>
          <template v-if="selectedIds.length > 0">
            <v-chip
              v-for="id in selectedIds"
              class="mr-2"
              @click:close="handleRemoveId(id)"
              v-bind:key="id"
              close
              >{{ id }}</v-chip
            >
          </template>
          <span class="mr-4 grey--text" v-else>ID не приписан</span>
          <v-btn @click="resetIdSelection" fab x-small color="primary">
            <v-icon>mdi-replay</v-icon>
          </v-btn>
        </div>
        <v-divider />
        <v-card-actions class="d-flex justify-end">
          <v-btn variant="outlined" @click="$emit('hide')">{{
            'Закрити'
          }}</v-btn>
          <v-btn
            :loading="saving"
            color="primary"
            variant="outlined"
            @click="setUserIds"
            >{{ 'Зберегти' }}</v-btn
          >
        </v-card-actions>
      </div>
      <v-snackbar
        :timeout="2000"
        v-model="showSuccessMessage"
        absolute
        color="success"
        outlined
        top
      >
        Збережено
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import customerRecordsApi from '@/api/customerRecords';
import { SEARCH_BY_FIELDS, TABLE_HEADERS } from './constants';

export default {
  name: 'AssignUserIdDialog',

  props: {
    show: Boolean,
  },

  data() {
    return {
      loading: false,
      saving: false,
      showSuccessMessage: false,

      searchByFields: [...SEARCH_BY_FIELDS],
      headers: [...TABLE_HEADERS],

      form: {
        fieldName: {
          value: 'email',
        },
        fieldValue: {
          value: '',
          error: '',
        },
      },

      searchResults: [],

      selectedIds: this.$store.getters['chat/currentChat'].userIds || [],
    };
  },

  computed: {
    currentChatId() {
      return this.$store.state.chat.currentChatId;
    },
  },

  watch: {
    currentChatId() {
      this.form.fieldName.value = 'email';
      this.form.fieldValue.value = '';
      this.searchResults = [];
      this.resetFormErrors();
      this.resetIdSelection();
    },
  },

  methods: {
    resetFormErrors() {
      this.form.fieldValue.error = '';
    },

    validateInput() {
      this.resetFormErrors();

      if (
        !this.form.fieldValue.value ||
        this.form.fieldValue.value.length === 0
      ) {
        this.form.fieldValue.error = 'Вимагане поле';
        return false;
      }

      if (
        this.form.fieldName.value === 'pesel' &&
        this.form.fieldValue.value.length !== 11
      ) {
        this.form.fieldValue.error = 'Неправильний песель';
        return false;
      }

      if (
        this.form.fieldName.value === 'bankAccountNumber' &&
        this.form.fieldValue.value.length !== 26
      ) {
        this.form.fieldValue.error = 'Неправильний номер рахунку';
        return false;
      }

      return true;
    },

    async findRecords() {
      if (!this.validateInput()) return;
      this.loading = true;
      const { fieldName, fieldValue } = this.form;
      try {
        const { data } = await customerRecordsApi.search({
          field: fieldName.value,
          value: fieldValue.value,
        });
        this.searchResults = data;
      } catch (e) {
        console.log('Error when searching for customer records');
      } finally {
        this.loading = false;
      }
    },

    async setUserIds() {
      this.saving = true;
      try {
        await this.$store.dispatch('chat/set_user_ids', {
          chatId: this.currentChatId,
          userIds: this.selectedIds,
        });
        this.showSuccessMessage = true;
      } catch (e) {
        console.log('Error when setting user IDs');
      } finally {
        this.saving = false;
      }
    },

    handleRowClick(item) {
      if (!this.selectedIds.includes(item.id)) {
        this.selectedIds = [...this.selectedIds, item.id];
      } else {
        this.handleRemoveId(item.id);
      }
    },

    handleRemoveId(id) {
      this.selectedIds = this.selectedIds.filter(
        selectedId => selectedId !== id
      );
    },

    resetIdSelection() {
      this.selectedIds = this.$store.getters['chat/currentChat'].userIds || [];
    },

    getItemClass(item) {
      return this.selectedIds.includes(item.id) ? 'indigo lighten-1' : '';
    },

    renderBooleanValue(value) {
      return value ? 'Так' : 'Ні';
    },
  },
};
</script>

<style lang="scss" scoped>
.search-row {
  width: 60%;
  gap: 24px;

  .v-text-field {
    max-width: 360px;
    &.v-select {
      max-width: 240px;
    }
  }
}
</style>
