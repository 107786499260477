<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title class="mb-4">
        <span class="text-h5">Редагувати Шаблон</span>
      </v-card-title>

      <v-card-text>
        <v-row v-if="messageTemplate && !messageTemplate.supportId">
          <v-col>
            <v-text-field
              v-model="folder"
              label="Назва папки..."
              outlined
              dense
              hide-details
              class="mb-4"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="subFolder"
              label="Назва підпапки..."
              outlined
              dense
              hide-details
              class="mb-4"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="title"
          label="Написати титулку шаблону..."
          outlined
          dense
          hide-details
          class="mb-4"
        />

        <v-textarea
          v-model="text"
          label="Написати шаблон..."
          auto-grow
          outlined
          dense
          hide-details
          rows="10"
          row-height="15"
        />

        <add-files-section :files="this.files" @change="handleFilesChange" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Закрити</v-btn>
        <v-btn color="blue darken-1" :loading="loading" text @click="save"
          >Зберегти</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddFilesSection from '../common/add-files-section/AddFilesSection.vue';

import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'MessageTemplatesEditDialog',
  components: {
    AddFilesSection,
  },

  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,

      loading: false,
      templateId: '',
      folder: '',
      subFolder: '',
      supportId: '',
      text: '',
      title: '',
      files: [],
    };
  },

  watch: {
    show(val) {
      if (val) {
        this.$store.commit(
          'ui/ADD_ACTION',
          'open-message-templates-edit-dialog',
          { root: true }
        );
        this.templateId = this.messageTemplate._id;
        this.folder = this.messageTemplate.path.split('/')[0];
        this.subFolder = this.messageTemplate.path.split('/')[1];
        this.supportId = this.messageTemplate.supportId;
        this.text = this.messageTemplate.text;
        this.title = this.messageTemplate.title;
        this.files = this.initializeFiles(this.messageTemplate.files);
      } else {
        setTimeout(() => {
          this.$store.commit(
            'ui/REMOVE_ACTION_IF_LAST',
            'open-message-templates-edit-dialog',
            { root: true }
          );
        }, 200);
      }
    },
  },

  computed: {
    show: {
      get() {
        return (
          this.$store.state.ui.currentDialog.name ===
          'messageTemplatesEditDialog'
        );
      },

      set(show) {
        if (!show)
          this.$store.commit(
            'ui/SET_CURRENT_DIALOG',
            { name: 'messageTemplatesDialog' },
            { root: true }
          );
      },
    },

    messageTemplate() {
      return this.show
        ? this.$store.state.ui.currentDialog.data.messageTemplate
        : null;
    },
  },

  methods: {
    /** @param files - Array<{ name: string;  mimetype: string; pathToFile: string }> */
    initializeFiles(files) {
      if (!files) return [];

      return files.map(file => {
        const thumbnail =
          file.mimetype.indexOf('image') === 0
            ? `${this.blobContainerUrl}${file.folder}/${file.id}`
            : null;

        return {
          file,
          ...(thumbnail ? { thumbnail } : {}),
        };
      });
    },

    save() {
      if (this.loading) return;
      this.loading = true;

      this.$store
        .dispatch(
          'messageTemplates/edit_message_template',
          {
            templateId: this.templateId,
            title: this.title,
            text: this.text,
            path: this.subFolder
              ? `${this.folder.trim()}/${this.subFolder.trim()}`
              : this.folder.trim(),
            supportId: this.supportId,
            files: this.files.map(item => item.file),
          },
          { root: true }
        )
        .then(res => {
          this.close();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.show = false;
    },

    handleFilesChange(newFiles) {
      this.files = newFiles;
    },
  },
};
</script>
