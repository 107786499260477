<template>
  <div>
    <app-insights-filters
      @change="handleFilterChange"
      @submit="handleSubmit"
      :date="date"
      :dateRange="dateRange"
      :event-type="eventType"
      :loading="loading"
    />
    <span class="ma-4 font-weight-bold">
      Статистика за
      {{ data[eventType].date || '---' }}
    </span>
    <hourly-chat-statistics-table
      v-if="eventType === EVENT.HOURLY_CHAT_STATISTICS"
      :data="data[eventType].rows"
      :loading="loading"
    />
    <department-insights-table
      v-else
      :data="data[eventType].rows"
      :loading="loading"
    />
  </div>
</template>

<script>
import AppInsightsFilters from './AppInsightsFilters.vue';
import HourlyChatStatisticsTable from './HourlyChatStatiscticsTable.vue';
import DepartmentInsightsTable from './DepartmentInsightsTable.vue';
import { getEvents } from '@/api/admin/events';
import { getISODate } from '@/lib/utils';
import { EVENT, DATE_RANGE } from '@/lib/const';

const { HOURLY_CHAT_STATISTICS, DEPARTMENT_ADD, DEPARTMENT_REMOVE } = EVENT;
const { DAY } = DATE_RANGE;

export default {
  name: 'AppInsights',

  components: {
    AppInsightsFilters,
    HourlyChatStatisticsTable,
    DepartmentInsightsTable,
  },

  data() {
    return {
      EVENT,
      data: {
        [HOURLY_CHAT_STATISTICS]: {
          date: getISODate(new Date()),
          rows: [],
        },
        [DEPARTMENT_ADD]: {
          date: '',
          rows: [],
        },
        [DEPARTMENT_REMOVE]: {
          date: '',
          rows: [],
        },
      },
      eventType: HOURLY_CHAT_STATISTICS,
      date: getISODate(new Date()),
      dateRange: DAY,
      loading: true,
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      (async () => {
        await this.getEvents();
      })();
    },

    async getEvents() {
      try {
        this.loading = true;
        const { data } = await getEvents({
          type: this.eventType,
          date: this.date,
          dateRange: this.dateRange,
        });
        this.data[this.eventType].rows = data;
        this.data[this.eventType].date = this.date;
      } catch (e) {
        console.error('Не зміг прогрузити getEvents =(', e);
        this.events = [];
      } finally {
        this.loading = false;
      }
    },

    handleFilterChange(key, value) {
      this[key] = value;
    },

    async handleSubmit() {
      await this.getEvents();
    },
  },
};
</script>
