<template>
  <v-dialog v-model="show" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon dark v-bind="attrs" v-on="on"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-card-title class="mb-4">
        <span class="text-h5">Новий Шаблон</span>
      </v-card-title>

      <v-card-text>
        <v-radio-group v-model="common" row>
          <v-radio label="Спільний" :value="true"></v-radio>
          <v-radio label="Мій" :value="false"></v-radio>
        </v-radio-group>

        <v-row v-if="common">
          <v-col>
            <v-text-field
              v-model="folder"
              label="Назва папки..."
              outlined
              dense
              hide-details
              class="mb-4"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="subFolder"
              label="Назва підпапки..."
              outlined
              dense
              hide-details
              class="mb-4"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="title"
          label="Написати титулку шаблону..."
          outlined
          dense
          hide-details
          class="mb-4"
        />

        <v-textarea
          v-model="text"
          label="Написати шаблон..."
          auto-grow
          outlined
          dense
          hide-details
          rows="10"
          row-height="15"
        />

        <add-files-section :files="this.files" @change="handleFilesChange" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Закрити</v-btn>
        <v-btn color="blue darken-1" :loading="loading" text @click="save"
          >Зберегти</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddFilesSection from '../common/add-files-section/AddFilesSection.vue';

export default {
  name: 'MessageTemplatesCreateDialog',
  components: {
    AddFilesSection,
  },

  data() {
    return {
      loading: false,
      text: '',
      title: '',
      folder: '',
      subFolder: '',
      common: true,
      files: [],
    };
  },

  computed: {
    show: {
      get() {
        return (
          this.$store.state.ui.currentDialog.name ===
          'messageTemplatesCreateDialog'
        );
      },

      set(show) {
        if (show) {
          this.$store.commit(
            'ui/SET_CURRENT_DIALOG',
            { name: 'messageTemplatesCreateDialog' },
            { root: true }
          );
          this.$store.commit(
            'ui/ADD_ACTION',
            'open-message-templates-create-dialog',
            { root: true }
          );
        } else {
          this.$store.commit(
            'ui/SET_CURRENT_DIALOG',
            { name: 'messageTemplatesDialog' },
            { root: true }
          );
          setTimeout(() => {
            this.$store.commit(
              'ui/REMOVE_ACTION_IF_LAST',
              'open-message-templates-create-dialog',
              { root: true }
            );
          }, 200);
        }
      },
    },
  },

  methods: {
    save() {
      if (this.loading) return;
      this.loading = true;

      this.$store
        .dispatch(
          'messageTemplates/create_message_template',
          {
            title: this.title,
            text: this.text,
            common: this.common,
            path: this.subFolder
              ? `${this.folder.trim()}/${this.subFolder.trim()}`
              : this.folder.trim(),
            files: this.files.map(item => item.file),
          },
          { root: true }
        )
        .then(messageTemplate => {
          this.close();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.show = false;

      this.title = '';
      this.text = '';
      this.folder = '';
      this.subFolder = '';
      this.files = [];
      this.common = true;
    },

    handleFilesChange(newFiles) {
      this.files = newFiles;
    },
  },
};
</script>
