<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="supports"
      :loading="loading"
      loading-text="Загружаю Менеджерів"
      sort-by="messagesCount"
      class="elevation-1"
      :items-per-page="15"
      :footer-props="{
        'items-per-page-options': [15, 30, 50, -1],
      }"
      height="calc(100vh - 123px)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Список менеджерів</v-toolbar-title>
          <v-spacer></v-spacer>
          <supports-create-dialog @create="addSupport"></supports-create-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.messagesCount="{ item }">
        <v-chip :color="getColor(item.messagesCount)">{{
          item.messagesCount || 0
        }}</v-chip>
      </template>

      <template v-slot:item.rating="{ item }">
        <div class="d-flex align-center justify-center">
          <div class="d-flex align-center mr-4">
            <v-icon color="green" size="medium" class="mr-1"
              >mdi-thumb-up-outline</v-icon
            >
            <div class="green--text">
              {{
                clientVoteCounts.has(item._id)
                  ? clientVoteCounts.get(item._id).good
                  : 0
              }}
            </div>
          </div>

          <div class="d-flex align-center mr-4">
            <v-icon color="red" size="medium" class="mr-1"
              >mdi-thumb-down-outline</v-icon
            >
            <div class="red--text">
              {{
                clientVoteCounts.has(item._id)
                  ? clientVoteCounts.get(item._id).bad
                  : 0
              }}
            </div>
          </div>

          <div class="d-flex align-center">
            <div class="d-flex align-center mr-4">
              <v-icon color="orange" size="medium" class="mr-1"
                >mdi-thumbs-up-down-outline</v-icon
              >
              <span class="green--text">{{
                clientVoteCounts.has(item._id)
                  ? clientVoteCounts.get(item._id).assisted.good
                  : 0
              }}</span>
              <span class="mx-1">|</span>
              <span class="red--text">{{
                clientVoteCounts.has(item._id)
                  ? clientVoteCounts.get(item._id).assisted.bad
                  : 0
              }}</span>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn @click="editSupport(item)" icon small class="mr-2">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn @click="deleteSupport(item._id)" icon small>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <supports-edit-dialog ref="edit-dialog"></supports-edit-dialog>
  </div>
</template>

<script>
import supportsApi from '@/api/admin/supports';
import SupportsCreateDialog from '@/components/admin/dialogs/SupportsCreateDialog';
import SupportsEditDialog from '@/components/admin/dialogs/SupportsEditDialog';
import { getObjectIndexByKeyValue } from '@/lib/utils';

export default {
  name: 'Managers',
  components: {
    SupportsCreateDialog,
    SupportsEditDialog,
  },

  data() {
    return {
      clientVotes: [],
      dialog: false,
      delete: false,
      loading: false,

      headers: [
        { text: "Ім'я", value: 'firstName' },
        { text: 'Прізвище', value: 'lastName' },
        { text: 'Повідомлення', value: 'messagesCount', align: 'center' },
        { text: 'Пошта', value: 'email' },
        { text: 'Нікнейм', value: 'nickname' },
        {
          text: 'Рейтинг (Good | Bad | Assist)',
          value: 'rating',
          align: 'center',
          sortable: false,
        },
        // { text: 'Активний', value: 'isActive', align: 'center' },
        { text: 'Дії', value: 'actions', align: 'right', sortable: false },
      ],
    };
  },

  computed: {
    clientVoteCounts() {
      let counts = new Map();

      this.supports.forEach(support => {
        counts.set(support._id, {
          good: this.clientVotes.filter(
            x => x.supportId === support._id && x.vote === 'good'
          ).length,
          bad: this.clientVotes.filter(
            x => x.supportId === support._id && x.vote === 'bad'
          ).length,
          assisted: {
            good: this.clientVotes.filter(
              x => x.assisted?.indexOf(support._id) >= 0 && x.vote === 'good'
            ).length,
            bad: this.clientVotes.filter(
              x => x.assisted?.indexOf(support._id) >= 0 && x.vote === 'bad'
            ).length,
          },
        });
      });

      return counts;
    },

    supports() {
      return this.$store.state.adminSupport.supports;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    getColor(calories) {
      if (calories > 100) return 'green';
      else if (calories > 50) return 'blue';
      else if (calories > 1) return 'orange';
      else return 'grey';
    },

    initialize() {
      this.loading = true;

      (async () => {
        await this.getClientVotes();

        this.loading = false;
      })();
    },

    async getClientVotes() {
      try {
        const limit = 2000;
        let skip = 0;
        let prevChatLength = 0;

        while (true) {
          let clientVotesRequest = await supportsApi.getClientVotes({
            limit,
            skip,
          });
          this.clientVotes = [...this.clientVotes, ...clientVotesRequest.data];

          skip = this.clientVotes.length;

          if (prevChatLength === this.clientVotes.length) break;

          prevChatLength = this.clientVotes.length;
        }
      } catch (e) {
        console.error('Не зміг прогрузити clientVotes =(', e);
      }
    },

    addSupport(support) {
      this.supports.push(support);
    },

    editSupport(support) {
      this.$refs['edit-dialog'].show = true;
      this.$refs['edit-dialog'].support = support;
      this.$refs['edit-dialog'].inputs = { ...support };
    },

    async deleteSupport(supportId) {
      if (!this.delete) {
        this.delete = true;
        setTimeout(() => {
          this.delete = false;
        }, 500);
        return;
      }

      await supportsApi
        .delete(supportId)
        .then(res => {
          let supportIndex = getObjectIndexByKeyValue(
            this.supports,
            supportId,
            '_id'
          );
          this.supports.splice(supportIndex, 1);
        })
        .catch(e => {
          console.error(e);
        });
    },
  },
};
</script>
