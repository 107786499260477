<template>
  <div>
    <v-btn @click="openFileInput" color="blue mt-4" text elevation="0">
      <input
        @change="addFiles"
        ref="file-input"
        type="file"
        multiple
        style="width: 0"
      />
      Добавить файлы
    </v-btn>

    <ul style="list-style: none; padding: 0" class="file-list">
      <li
        v-for="(fileItem, index) in files"
        class="file-list__item"
        v-bind:key="index"
      >
        <div v-if="fileItem.thumbnail" style="position: relative">
          <div class="thumbnail">
            <div class="thumbnail__container">
              <div
                class="thumbnail__image"
                :style="{
                  'background-image': `url(${fileItem.thumbnail})`,
                }"
              ></div>
            </div>
          </div>

          <div
            style="
              position: absolute;
              top: 0.25rem;
              right: 0.25rem;
              background: rgba(69, 90, 100, 0.6);
            "
            class="d-flex align-center rounded"
          >
            <v-file-input
              @change="changeFile($event, index)"
              prepend-icon="mdi-sync"
              class="reload-btn mr-1 mt-0"
              hide-input
              dense
              dark
            />

            <v-btn @click="removeFile(index)" small dark icon>
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>

        <div v-else>
          <div class="d-flex align-center justify-space-between py-2">
            <div :class="{ 'red--text': fileItem.status === 'error' }">
              <v-icon dense :color="fileItem.status === 'error' ? 'red' : ''"
                >mdi-file-outline</v-icon
              >
              <span
                class="d-inline-block ml-1 text-truncate"
                style="max-width: 240px; vertical-align: middle"
                >{{ fileItem.file.name }}</span
              >
            </div>

            <div class="rounded d-flex align-center">
              <v-file-input
                @change="changeFile($event, index)"
                prepend-icon="mdi-sync"
                class="reload-btn mr-1 mt-0"
                hide-input
                dense
              />

              <v-btn @click="removeFile(index)" small icon>
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'AddFilesSection',

  props: {
    files: Array,
  },

  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,
    };
  },

  methods: {
    openFileInput() {
      this.$refs['file-input'].click();
    },

    addFiles(e) {
      const files = Array.from(e.currentTarget.files);
      if (files.length === 0) return;

      const filesWithThumbnails = files.map(file => {
        const thumbnail =
          file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;

        return {
          file,
          ...(thumbnail ? { thumbnail } : {}),
        };
      });

      const newFiles = [...this.files, ...filesWithThumbnails];

      this.$emit('change', newFiles);
    },

    changeFile(file, index) {
      const thumbnail =
        file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;

      file = {
        file,
        ...(thumbnail ? { thumbnail } : {}),
      };

      const newFiles = [...this.files];
      newFiles.splice(index, 1, file);

      this.$emit('change', newFiles);
    },

    removeFile(index) {
      const newFiles = [...this.files];
      newFiles.splice(index, 1);

      this.$emit('change', newFiles);
    },
  },
};
</script>

<style lang="scss">
.file-list {
  &__item {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.thumbnail {
  border-radius: 8px;
  overflow: hidden;

  &__container {
    cursor: pointer;
    background: #3d3d3d;
  }

  &__image {
    cursor: pointer;
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3d3d3d;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
  }
}

.reload-btn {
  .v-input__prepend-outer {
    margin: 0;
  }

  .v-icon {
    font-size: 1rem;
  }
}
</style>
