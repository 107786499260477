import supportsApi from '@/api/admin/supports';

const state = {
  supports: [],
};

const getters = {
  getSupportByAzureId: state => supportAzureId => {
    return (
      state.supports.find(
        support => support.azureProviderId === supportAzureId
      ) || {}
    );
  },
};

const mutations = {
  SET_SUPPORTS(state, { supports }) {
    const supportIds = state.supports.map(support => support.supportId);

    supports.forEach(support => {
      if (supportIds.indexOf(support.supportId) < 0)
        state.supports.push(support);
    });
  },
};

const actions = {
  async get_supports({ commit }) {
    try {
      let supportsResponse = await supportsApi.getAll({
        limit: 200000,
        skip: 0,
      });

      let supports = supportsResponse.data;

      commit('SET_SUPPORTS', { supports });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
