<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      loading-text="Загрузка..."
      sort-by="createdAt"
      class="elevation-1"
      :items-per-page="-1"
      hide-default-footer
      height="calc(100vh - 123px)"
    >
      <template v-slot:item.createdAt="{ item }">
        <span>{{ formatDate(item.createdAt) }}</span>
      </template>

      <template v-slot:item.unreadChats="{ item }">
        <span>{{ item.data.unreadChats }}</span>
      </template>

      <template v-slot:item.largestReplyDelayInMinutes="{ item }">
        <span>{{ item.data.largestReplyDelayInMinutes }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatDate, formatTime } from '@/lib/utils';

export default {
  name: 'HourlyChatStatiscticsTable',

  props: {
    data: Array,
    loading: Boolean,
  },

  data() {
    return {
      headers: [
        { text: 'Дата', value: 'createdAt' },
        { text: 'Непрочитанных', value: 'unreadChats' },
        {
          text: 'Максимальное время ожидания (минут)',
          value: 'largestReplyDelayInMinutes',
        },
      ],
    };
  },

  methods: {
    formatDate(date) {
      return formatDate(date) + ' | ' + formatTime(date);
    },
  },
};
</script>
