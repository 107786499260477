<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="bulkMessages"
      :loading="loading"
      loading-text="Загружаю Розсилки"
      v-model:expanded="expanded"
      item-key="_id"
      sort-by="messagesCount"
      class="elevation-1"
      :items-per-page="15"
      :footer-props="{
        'items-per-page-options': [15, 30, 50, -1],
      }"
      height="calc(100vh - 123px)"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Список рассылок</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="() => openDialog()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ item: item }">
        <td :colspan="9" class="pa-0">
          <v-data-table
            v-if="item.items"
            :headers="bulkMessageItemsHeader"
            :items="item.items"
            class="elevation-0"
          >
            <template v-slot:item.chatId="{ item }">
              <v-avatar color="teal" size="32" class="mr-3">
                <img
                  v-if="chatsById.get(item.chatId).avatar"
                  :src="blobContainerUrl + chatsById.get(item.chatId).avatar"
                />
                <span v-else class="white--text">{{
                  chatsById
                    .get(item.chatId)
                    .from.first_name.charAt(0)
                    .toUpperCase()
                }}</span>
              </v-avatar>
              <span>{{ getName(item.chatId) }}</span>
            </template>

            <template v-slot:item.scheduleTime="{ item }">
              <span>{{ formatDate(item.scheduleTime) }}</span>
            </template>

            <template v-slot:item.status="{ item }">
              <v-icon :color="getStatusColor(item.status)">mdi-av-timer</v-icon>
            </template>
          </v-data-table>
        </td>
      </template>

      <template v-slot:item.title="{ item }">
        <span>{{
          item.title.length > 15 ? `${item.title.slice(0, 15)}...` : item.title
        }}</span>
      </template>

      <template v-slot:item.message="{ item }">
        <span>{{
          item.message.length > 15
            ? `${item.message.slice(0, 15)}...`
            : item.message
        }}</span>
      </template>

      <template v-slot:item.scheduleTimeStart="{ item }">
        <span>{{ formatDate(item.scheduleTime) }}</span>
      </template>

      <template v-slot:item.scheduleTimeEnd="{ item }">
        <span>
          {{
            item.items && item.items.length
              ? formatDate(item.items[item.items.length - 1].scheduleTime)
              : 'Немає часу'
          }}
        </span>
      </template>

      <template v-slot:item.sent="{ item }">
        <span>
          {{
            item.items && item.items.length
              ? `${item.items.filter(x => x.status === 'sent').length}/${
                  item.items.length
                }`
              : 'Немає кількості'
          }}
        </span>
      </template>

      <template v-slot:item.status="{ item }">
        <div :title="getBulkMessageStatus(item).title">
          <v-icon :color="getBulkMessageStatus(item).color">{{
            getBulkMessageStatus(item).icon
          }}</v-icon>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="isCancelable(item)"
          @click="cancelBulkMessage(item)"
          icon
          small
          class="mr-2"
          title="Відмінити"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>

        <v-btn
          v-if="isEditable(item)"
          @click="() => openDialog(item)"
          icon
          small
          class="mr-2"
          title="Редагувати"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-if="isRemovable(item)"
          @click="deleteBulkMessage(item._id)"
          icon
          small
          title="Видалити"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <bulk-messages-dialog
      :show="show"
      :bulkMessageToEdit="bulkMessageToEdit"
      @create="addBulkMessage"
      @edit="editBulkMessage"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import bulkMessagesApi from '@/api/admin/bulkMessages';
import BulkMessagesDialog from '@/components/admin/dialogs/BulkMessagesDialog/BulkMessagesDialog';
import { getObjectIndexByKeyValue, formatDate, formatTime } from '@/lib/utils';
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'BulkMessages',
  components: {
    BulkMessagesDialog,
  },

  data() {
    return {
      bulkMessages: [],
      show: false,
      delete: false,
      cancel: false,
      loading: false,
      bulkMessageToEdit: null,

      expanded: [],
      headers: [
        { text: 'Название', value: 'title' },
        { text: 'Сообщение', value: 'message' },
        { text: 'Начало', value: 'scheduleTimeStart', align: 'center' },
        { text: 'Завершение', value: 'scheduleTimeEnd', align: 'center' },
        {
          text: 'Приблизительное количество',
          value: 'approximateNumberOfItems',
          align: 'center',
        },
        {
          text: 'Отправлено',
          value: 'sent',
          align: 'center',
          sortable: false,
        },
        { text: 'Статус', value: 'status', align: 'center' },
        { text: 'Действия', value: 'actions', align: 'right', sortable: false },
      ],

      bulkMessageItemsHeader: [
        { text: 'Кому', value: 'chatId' },
        { text: 'На дату', value: 'scheduleTime', align: 'center' },
        { text: '', value: '', align: '', sortable: false },
        { text: '', value: '', align: '', sortable: false },
        {
          text: 'Ошибка',
          value: 'errorMessage',
          align: 'center',
          sortable: false,
        },
        { text: 'Статус', value: 'status', align: 'center', sortable: false },
      ],

      blobContainerUrl: BLOB_CONTAINER_URL,
    };
  },

  computed: {
    chatsById() {
      return new Map(this.$store.state.adminChat.chats.map(i => [i.chatId, i]));
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    openDialog(bulkMessage) {
      this.show = true;
      if (bulkMessage) {
        this.bulkMessageToEdit = bulkMessage;
      }
    },

    closeDialog() {
      this.show = false;
      this.bulkMessageToEdit = null;
    },

    isRemovable(bulkMessage) {
      let removableStatuses = [
        'pending',
        'scheduled',
        'canceled',
        'error',
        'retry_scheduled',
      ];
      return removableStatuses.indexOf(bulkMessage.status) >= 0;
    },

    isEditable(bulkMessage) {
      let editableStatuses = ['pending', 'scheduled', 'retry_scheduled'];
      return editableStatuses.indexOf(bulkMessage.status) >= 0;
    },

    isCancelable(bulkMessage) {
      let cancelableStatuses = ['pending', 'scheduled', 'retry_scheduled'];
      return cancelableStatuses.indexOf(bulkMessage.status) >= 0;
    },

    getName(chatId) {
      let chat = this.chatsById.get(chatId);
      let name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;
      if (name.trim().length === 0) name = chat.from.username;

      return name.trim() || 'No name';
    },

    getBulkMessageStatus(bulkMessage) {
      const bulkMessageItems = bulkMessage.items;
      const sentBulkMessageItemsLen = bulkMessageItems.filter(
        x => x.status === 'sent'
      ).length;
      const errBulkMessageItemsLen = bulkMessageItems.filter(
        x => x.status === 'error'
      ).length;
      let status = {
        color: '',
        title: '',
      };

      switch (true) {
        case bulkMessage.status === 'canceled':
          status = {
            icon: 'mdi-close-circle-outline',
            color: 'grey',
            title: 'Отменен',
          };
          break;

        case bulkMessage.status === 'retry_scheduled':
          status = {
            icon: 'mdi-schedule',
            color: 'orange',
            title: 'Отсрочен',
          };
          break;

        case sentBulkMessageItemsLen === 0:
          status = {
            icon: 'mdi-av-timer',
            color: 'grey',
            title: 'Неотправлен',
          };
          break;

        case sentBulkMessageItemsLen < bulkMessageItems.length:
          status = {
            icon: 'mdi-av-timer',
            color: 'orange',
            title: 'Есть неотправленные',
          };
          break;

        case sentBulkMessageItemsLen === bulkMessageItems.length:
          status = {
            icon: 'mdi-check-all',
            color: 'green',
            title: 'Все отправилось!',
          };
          break;

        case errBulkMessageItemsLen >= bulkMessageItems.length / 2:
          status = {
            icon: 'mdi-av-timer',
            color: 'error',
            title: 'Больше половины неотправленные',
          };
          break;
      }

      return status;
    },

    getStatusColor(status) {
      let color = '';

      switch (status) {
        case 'pending':
          color = 'grey';
          break;

        case 'sent':
          color = 'green';
          break;

        case 'error':
          color = 'red';
          break;
      }

      return color;
    },

    formatDate(date) {
      return formatDate(date) + ' | ' + formatTime(date);
    },

    initialize() {
      this.getBulkMessages();
    },

    getBulkMessages() {
      this.loading = true;

      bulkMessagesApi
        .getAll()
        .then(res => {
          this.bulkMessages = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addBulkMessage(bulkMessage) {
      this.bulkMessages.push(bulkMessage);
    },

    editBulkMessage(bulkMessage) {
      const bulkMessageToEditIndex = this.bulkMessages.findIndex(
        ({ _id }) => bulkMessage._id === _id
      );
      this.bulkMessages[bulkMessageToEditIndex] = bulkMessage;
      this.bulkMessages = [...this.bulkMessages];
    },

    async cancelBulkMessage(bulkMessage) {
      if (!this.cancel) {
        this.cancel = true;
        setTimeout(() => {
          this.cancel = false;
        }, 500);
        return;
      }

      await bulkMessagesApi
        .cancel(bulkMessage._id)
        .then(res => {
          bulkMessage.items = [];
          bulkMessage.status = 'canceled';
        })
        .catch(e => {
          console.error(e);
        });
    },

    async deleteBulkMessage(bulkMessageId) {
      if (!this.delete) {
        this.delete = true;
        setTimeout(() => {
          this.delete = false;
        }, 500);
        return;
      }

      await bulkMessagesApi
        .delete(bulkMessageId)
        .then(res => {
          let bulkMessageIndex = getObjectIndexByKeyValue(
            this.bulkMessages,
            bulkMessageId,
            '_id'
          );
          this.bulkMessages.splice(bulkMessageIndex, 1);
        })
        .catch(e => {
          console.error(e);
        });
    },
  },
};
</script>

<style scoped></style>
