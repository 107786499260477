export const BULK_MESSAGES_FILTER_TYPE = {
  LANGUAGE: 'language',
  PAY_FREQUENCY: 'payFrequency',
  PLATFORM: 'platform',
  IS_CASH_PAY: 'isCashPay',
  APP: 'app',
  NATIONALITY: 'nationality',
  CITY: 'city',
  IS_CONTRACT: 'isContract',
  CONTRACT_TYPE: 'contractType',
  IS_STUDENT: 'isStudent',
  IS_TAXI_DRIVER: 'isTaxiDriver',
  IS_DOCUMENT_MISSING: 'isDocumentMissing',
  USER_IDS_CSV: 'userIdsCsv',
  USER_IDS: 'userIds',
};

export const COUNTRIES = [
  'Afganistan',
  'Afghan',
  'Afghanistan',
  'Algeria',
  'ALGERIENNE',
  'Arab Republic of Egypt',
  'Armenia',
  'Azerbaijan',
  'Azerbajdjan',
  'AZERBAYCAN',
  'Azerbejdzan',
  'Azerbejdżan',
  'BANGLADESHI',
  'Belarus',
  'BLR',
  'Bulgaria',
  'Cameroonian',
  'Democratic Republic of the Congo',
  'DEMOCRATIC SOCIALIST REPUBLIC OF SRI LANKA',
  'dgasgsdfgsd',
  'Egypt',
  'Egyptian',
  'Ethiopia',
  'Ethiopian',
  'Etiopia',
  'Federal Democratic Republic Of Ethiopia',
  'Federal Democratic Republic of Nepal',
  'Federal Republic od Nigeria',
  'Federal Republic of Germany',
  'Federal Republic of Nigeria',
  'Federation of Malaya',
  'Filipino',
  'Gabon',
  'Gambian',
  'Geogia',
  'Geordia',
  'Georgia',
  'Giorgia',
  'Gruzja',
  'Guineenne',
  'Hashemite Kingdom of Jordan',
  'India',
  'Indian',
  'Indonesia',
  'Iraq',
  'Islamic Emirate of Afghanistan',
  'Islamic Republic of Afghanistan',
  'ISLAMIC REPUBLIC OF IRAN',
  'Islamic Republic of Pakistan',
  'Jordan',
  'Jordanian',
  'Kazakhstan',
  'Kazakstan',
  'Kenya',
  'KINGDOM OF MOROCCO',
  'Kingdom of Morroco',
  'KYRGYZ REPUBLIC',
  'Kyrgyzstan',
  'Latvia',
  'Marocaine',
  'Mocambicana',
  'Moldova',
  'Monatele Kamerun',
  'Mongolia',
  'Mozambique',
  'Nepal',
  'Nigeria',
  'Nigerian',
  'Pakistan',
  'PAKISTANI',
  'Peoples Democratic Republic of Algeria',
  'Peoples Republic of Bangladesh',
  'Peoples Republic of China',
  'Philippines',
  'Poland',
  'Polska',
  'POLSKIE',
  'Portugal',
  'Portuguese Republic',
  'Rebublic of Turkey',
  'Republic Kazakhstan',
  'REPUBLIC MOLDOVA',
  'Republic of Afganistan',
  'Republic of Algeria',
  'Republic of Angola',
  'Republic of Armeina',
  'Republic of Armenia',
  'Republic of Azerbaijan',
  'Republic of Bangladesh',
  'Republic of Belarus',
  'Republic of Bulgaria',
  'REPUBLIC OF BURUNDI',
  'Republic of Cameroon',
  'Republic of China',
  'Republic of Congo',
  'Republic of Cuba',
  'Republic of Ethiopia',
  'Republic of Finland',
  'Republic of Ghana',
  'Republic of India',
  'Republic of Iraq',
  'Republic of Italy',
  'Republic of Kazakhstan',
  'Republic of Kenya',
  'Republic of Kosovo',
  'Republic of Liberia',
  'Republic of Lithuania',
  'Republic of Moldova',
  'Republic of Mozambique',
  'Republic of Nigeria',
  'REPUBLIC OF PAKISTAN',
  'Republic of Peru',
  'Republic of Poland',
  'Republic of Rwanda',
  'Republic of Serbia',
  'Republic of Tajikistan',
  'Republic of Tunisia',
  'REPUBLIC OF TURKEY',
  'Republic of Turkiye',
  'REPUBLIC OF TURKMENISTAN',
  'Republic of Uzbekistan',
  'Republic of Zimbabwe',
  'Republica Federativa Do Brasil',
  'Republica Moldova',
  'Republik of Armenia',
  'Republik of Azerbaijan',
  'REPUBLIK OF BELARUS',
  'Republik of Moldova',
  'Republika Moldova',
  'Repudlic of Belarus',
  'Respublic of Zimbabwe',
  'Romania',
  'Rosja',
  'Rupublic of Rwanda',
  'Russian',
  'Russian Federation',
  'Russian of federation',
  'Rwanda',
  'Rwandan',
  'Rzeczpispolita Polska',
  'Rzeczpospolita Polska',
  'Socialist Republic of Vietnam',
  'South Africa',
  'SOUTH AFRICAN',
  'SRI LANKAN',
  'State of Israel',
  'State of Libya',
  'State of Palestine',
  'Syria',
  'Syrian Arab Republic',
  'Tadżykistan',
  'Tajikistan',
  'Tashkent',
  'The Kyrgyz Republic',
  'The Republic of Zimbabwe',
  'Tunisia',
  'TUR',
  'Turcja',
  'Tureckie',
  'Turkey',
  'TURKMENISTAN',
  'Turkmensistan',
  'Ukraina',
  'Ukraine',
  'Umurundi',
  'United Arab Emirates',
  'United Republic of Tanzania',
  'UNITED STATES OF AMERICA',
  'Uzbekistan',
  'Warszawa',
  'Zimbabwe',
  'ZIMBABWEAN',
];

export const CITIES = [
  'Bialystok',
  'Bielskobiala',
  'Bydgoszcz',
  'Czestochowa',
  'Dabrowa Gornicza',
  'Elk',
  'Gdansk',
  'Gliwice',
  'Gorzów Wielkopolski',
  'Jelenia Gora',
  'Kalisz',
  'Katowice',
  'Kielce',
  'Krakow',
  'Legnica',
  'Leszno',
  'Lodz',
  'Lublin',
  'Minsk Mazowiecki',
  'Olsztyn',
  'Opole',
  'Piotrków Trybunalski',
  'Plock',
  'Poznan',
  'Radom',
  'Rzeszow',
  'Siedlce',
  'Slupsk',
  'Sochaczew',
  'Suwalki',
  'Szczecin',
  'Tarnowskie Góry',
  'Torun',
  'Warszawa',
  'Wloclawek',
  'Wroclaw',
  'Zielona gora',
];
