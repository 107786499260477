import axios from 'axios';
import { EVENT, DATE_RANGE } from '@/lib/const';
import { getISODate } from '@/lib/utils';

const headers = {
  headers: {
    Authorization: window.localStorage.getItem('token'),
  },
};
const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const originPath = `${origin}/${prefix}/admins/events`;
const routes = {
  get: {
    events: originPath,
  },
};

export const getEvents = ({
  type = EVENT.HOURLY_CHAT_STATISTICS,
  date = getISODate(new Date()),
  dateRange = DATE_RANGE.DAY,
}) => {
  const params = `?${new URLSearchParams({
    type,
    date,
    dateRange,
  }).toString()}`;

  const url = routes.get.events + params;
  return axios.get(url, headers);
};
