<template>
  <div>
    <v-data-table
      v-model:expanded="expanded"
      item-key="department"
      :headers="headers"
      :items="data"
      :loading="loading"
      loading-text="Загрузка..."
      sort-by="count"
      class="elevation-1"
      :items-per-page="-1"
      hide-default-footer
      height="calc(100vh - 123px)"
      show-expand
    >
      <template v-slot:expanded-item="{ item }">
        <td :colspan="4" class="pa-0">
          <v-data-table
            class="grey pl-14"
            :headers="supportIdCountsHeaders"
            :items="item.supportIdCounts"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:item.supportId="{ item }">
              <span>{{
                getSupportByAzureId(item.supportId)?.nickname || item.supportId
              }}</span>
            </template>
          </v-data-table>
        </td>
      </template>

      <template v-slot:item.department="{ item }">
        <span>{{ getDepartmentName(item.department) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DEPARTMENTS } from '@/lib/const';

export default {
  name: 'DepartmentInsightsTable',

  props: {
    data: Array,
    loading: Boolean,
  },

  data() {
    return {
      departments: DEPARTMENTS,
      headers: [
        {
          text: 'Отдел',
          value: 'department',
          width: 500,
          sortable: false,
          cellClass: 'font-weight-bold',
        },
        { text: 'Количество', value: 'total' },
      ],
      supportIdCountsHeaders: [
        { text: 'Саппорт', value: 'supportId', width: 400 },
        { text: 'Количество', value: 'count' },
      ],
      expanded: [],
    };
  },

  computed: {
    ...mapGetters('adminSupport', {
      getSupportByAzureId: 'getSupportByAzureId',
    }),
  },

  methods: {
    getDepartmentName(departmentId) {
      return (
        this.departments.find(({ id }) => id === departmentId)?.name ||
        departmentId
      );
    },
  },
};
</script>
