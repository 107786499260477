<template>
  <div class="mb-4 d-flex flex-column">
    <radio-filter
      @change="value => handleChange(filterType.LANGUAGE, value)"
      title="Язык"
      :disabled="filters[filterType.NATIONALITY]?.length > 0"
      :options="languageOptions"
      :value="filters[filterType.LANGUAGE]"
    />
    <radio-filter
      @change="value => handleChange(filterType.PAY_FREQUENCY, value)"
      title="Тип выплати"
      :disabled="isUserIdsFilterActive"
      :options="payFrequencyTypeOptions"
      :value="filters[filterType.PAY_FREQUENCY]"
    />
    <radio-filter
      @change="value => handleChange(filterType.PLATFORM, value)"
      title="Платформа"
      :disabled="isUserIdsFilterActive"
      :options="platformOptions"
      :value="filters[filterType.PLATFORM]"
    />
    <radio-filter
      @change="value => handleChange(filterType.IS_CASH_PAY, value)"
      title="Выплата наличными"
      :disabled="isUserIdsFilterActive"
      :options="booleanOptions"
      :value="filters[filterType.IS_CASH_PAY]"
    />
    <select-filter
      @change="value => handleChange(filterType.APP, value)"
      title="Приложение"
      :disabled="isUserIdsFilterActive"
      :options="appOptions"
      :value="filters[filterType.APP]"
    />
    <autocomplete-filter
      @change="value => handleChange(filterType.NATIONALITY, value)"
      title="Гражданство"
      :disabled="!!filters[filterType.LANGUAGE] || isUserIdsFilterActive"
      :options="nationalityOptions"
      :value="filters[filterType.NATIONALITY]"
    />
    <autocomplete-filter
      @change="value => handleChange(filterType.CITY, value)"
      title="Город"
      :disabled="isUserIdsFilterActive"
      :options="cityOptions"
      :value="filters[filterType.CITY]"
    />
    <radio-filter
      @change="value => handleChange(filterType.IS_CONTRACT, value)"
      title="Umowa zlecenia"
      :disabled="isUserIdsFilterActive"
      :options="booleanOptions"
      :value="filters[filterType.IS_CONTRACT]"
    />
    <radio-filter
      @change="value => handleChange(filterType.CONTRACT_TYPE, value)"
      title="Etat"
      :disabled="isUserIdsFilterActive"
      :options="contractTypeOptions"
      :value="filters[filterType.CONTRACT_TYPE]"
    />
    <radio-filter
      @change="value => handleChange(filterType.IS_STUDENT, value)"
      title="Студент"
      :disabled="isUserIdsFilterActive"
      :options="booleanOptions"
      :value="filters[filterType.IS_STUDENT]"
    />
    <radio-filter
      @change="value => handleChange(filterType.IS_TAXI_DRIVER, value)"
      title="Umowa taksówkarz"
      :disabled="isUserIdsFilterActive"
      :options="booleanOptions"
      :value="filters[filterType.IS_TAXI_DRIVER]"
    />
    <radio-filter
      @change="value => handleChange(filterType.IS_DOCUMENT_MISSING, value)"
      title="Без документов"
      :disabled="isUserIdsFilterActive"
      :options="booleanOptions"
      :value="filters[filterType.IS_DOCUMENT_MISSING]"
    />
    <v-file-input
      accept="text/csv"
      :label="
        filters[filterType.USER_IDS]?.length
          ? userIdsCsvOriginalFileName
          : 'Добавьте csv файл с user ids'
      "
      @change="value => handleChange(filterType.USER_IDS_CSV, value)"
      :value="filters[filterType.USER_IDS_CSV]"
    />
    <v-btn
      @click="$emit('resetFilters')"
      class="mt-4"
      small
      text
      color="primary"
    >
      Почистити фільтри
    </v-btn>
  </div>
</template>

<script>
import RadioFilter from './filters/RadioFilter.vue';
import SelectFilter from './filters/SelectFilter.vue';
import AutocompleteFilter from './filters/AutocompleteFilter.vue';
import { BULK_MESSAGES_FILTER_TYPE, COUNTRIES, CITIES } from './constants';

export default {
  name: 'BulkMessagesFilters',
  components: {
    RadioFilter,
    SelectFilter,
    AutocompleteFilter,
  },

  props: {
    filters: Object,
    userIdsCsvOriginalFileName: String,
  },

  data() {
    return {
      filterType: BULK_MESSAGES_FILTER_TYPE,
      languageOptions: [
        { label: 'Английский', value: 'en' },
        { label: 'Узбецкий', value: 'uzb' },
        { label: 'Польский', value: 'pl' },
        { label: 'Русский', value: 'ru' },
      ],
      payFrequencyTypeOptions: [
        { label: 'VIP', value: 'daily' },
        { label: 'Weekly', value: 'weekly' },
      ],
      platformOptions: [
        { label: 'Taxi', value: 'taxi' },
        { label: 'Delivery', value: 'delivery' },
      ],
      payTypeOptions: [
        { label: 'Наличные', value: 'cash' },
        { label: 'Карта', value: 'card' },
      ],
      appOptions: [
        { text: 'Uber Driver', value: 'uber' },
        { text: 'Uber Eats', value: 'uberEats' },
        { text: 'Bolt Driver', value: 'bolt' },
        { text: 'Bolt Food', value: 'boltFood' },
        { text: 'FreeNow', value: 'freeNow' },
        { text: 'iTaxi', value: 'iTaxi' },
        { text: 'Glovo', value: 'glovo' },
        { text: 'Wolt', value: 'wolt' },
        { text: 'Stuart', value: 'stuart' },
      ],
      nationalityOptions: COUNTRIES.map(country => ({
        text: country,
        value: country,
      })),
      cityOptions: CITIES.map(city => ({
        text: city,
        value: city,
      })),
      contractTypeOptions: [
        { label: 'Pelny', value: 'fullTime' },
        { label: '1/8', value: 'partTime' },
      ],
      booleanOptions: [
        { label: 'Да', value: true },
        { label: 'Нет', value: false },
      ],
    };
  },

  computed: {
    isUserIdsFilterActive() {
      return !!(
        this.filters[this.filterType.USER_IDS]?.length ||
        this.filters[this.filterType.USER_IDS_CSV]
      );
    },
  },

  methods: {
    handleChange(key, value) {
      this.$emit('change', key, value);
    },
  },
};
</script>
